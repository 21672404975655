<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="书评"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="review">
            <p>
                <img :src="listList.coverpic" alt="" class="book_img">
            </p>
            <div class="review-rigth">
                <div>
                    <p class="review-title">{{listList.title}}</p>
                    <p class="review-title-button" @click="readingClick">免费阅读</p>
                </div>
                <div>
                    <p class="review-subtitle" v-if="listList.average_star == 1">不好</p>
                    <p class="review-subtitle" v-if="listList.average_star == 2">一般</p>
                    <p class="review-subtitle" v-if="listList.average_star == 3">还行</p>
                    <p class="review-subtitle" v-if="listList.average_star == 4">不错</p>
                    <p class="review-subtitle" v-if="listList.average_star == 5">力荐</p>
                    <div>
                        <van-rate v-model="listList.average_star" :icon="img" :void-icon="img1" size="13" readonly/>
                    </div>
                    <p class="review-rigth-text">{{listList.total_score_person}}人评分 <span
                            v-if="listList.average_score != 0">{{listList.average_score}}分</span></p>
                </div>
            </div>
        </div>
        <div class="review-book">
            <van-tabs v-model="active"
                      swipeable
                      :border="false"
                      animated
                      title-active-color="#0A1C33"
                      title-inactive-color="#BFC2CC"
                      @change="changeClick"
            >
                <template v-for="item in List">
                    <van-tab :title="item">
                        <div class="wel" v-if="loading_show">
                            <van-loading size="20px">加载中...</van-loading>
                        </div>
                        <template v-if="moreList">
                            <div class="no" v-if="moreList.length == 0 && !loading_show">
                                <No title="暂无评价"></No>
                            </div>
                        </template>
                        <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    :finished="finished"
                                    :immediate-check="false"
                                    :finished-text="finished_text"
                                    @load="onLoad"
                            >
                                <template v-for="(item,index) in moreList">
                                    <div>
                                        <div class="review-book-top">
                                            <p>
                                                <img :src="item.avatar" alt="" class="book_img1">
                                            </p>
                                            <div style="width: 100%">
                                                <div class="review-book-top-left">
                                                    <p class="review-book-title" @click="bookdetailsClick(item)">
                                                        {{item.nickname}}</p>
                                                    <div>
                                                        <span class="review-book-subtitle"
                                                              v-if="item.star == 1">不好</span>
                                                        <span class="review-book-subtitle"
                                                              v-if="item.star == 2">一般</span>
                                                        <span class="review-book-subtitle"
                                                              v-if="item.star == 3">还行</span>
                                                        <span class="review-book-subtitle"
                                                              v-if="item.star == 4">不错</span>
                                                        <span class="review-book-subtitle"
                                                              v-if="item.star == 5">力荐</span>
                                                        <van-rate v-model="item.star" :icon="img" :void-icon="img1"
                                                                  size="13" readonly/>
                                                    </div>
                                                </div>
                                                <p class="sentiment-text" @click="bookdetailsClick(item)">
                                                    {{item.content}}
                                                </p>
                                                <div class="support">
                                                    <p class="support-time">{{item.created_at}}</p>
                                                    <p class="support-number"
                                                       @click="do_likes(item,index)"
                                                       :class="item.is_admire == 1?'text_active':''">
                                                        <img src="../../assets/images/icon1.png" alt="" class="icon-img"
                                                             v-if="item.is_admire == 1">
                                                        <img src="../../assets/images/icon.png" alt="" class="icon-img"
                                                             v-else>
                                                        {{item.likes}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </van-list>
                        </van-pull-refresh>
                    </van-tab>
                </template>
            </van-tabs>
        </div>
        <div class="reading-fixed">
            <div class="reading-list" @click="evaluationClick">
                <p class="reading-title">
                    <img src="../../assets/images/Write_icon.png" alt="" class="Plus_icon">
                    写评论</p>
            </div>
        </div>
    </div>
</template>

<script>
    import No from '../../components/No'

    export default {
        name: "BookReview",
        components: {
            No,
        },
        data() {
            return {
                img: require("../../assets/images/Stars_icon.png"),
                img1: require("../../assets/images/Stars_icon_50.png"),
                List: ['最新书评', '推荐书评'],
                id: this.$route.query.id,
                active: 0,
                page: 1,
                page_size: 10,
                listList: [],
                loading_show: false,
                moreList: [],
                moreList_show: false,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: '',

            }
        },
        created() {
            this.listGET()
        },
        methods: {
            listGET() {
                this.$axios.get('/api/comment/list', {
                    params: {
                        anime_id: this.id,
                        type: this.active,
                        page: this.page,
                        page_size: this.page_size,
                    }
                })
                    .then(res => {
                        this.loading_show = false
                        var res = res.data.data
                        if (res.list.length < this.page_size) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.page == 1) {
                            this.finished_text = ''
                        }

                        this.refreshing = false
                        this.loading = false;
                        this.listList = res
                        if (this.moreList_show) {
                            this.moreList = res.list
                            this.moreList_show = false
                        } else {
                            this.moreList = this.moreList.concat(res.list)
                        }

                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },

            onRefresh() {
                //下拉刷新
                this.page = 1
                if (this.moreList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.listGET()
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.listGET();
            },
            changeClick() {
                this.loading_show = true
                this.moreList = []
                this.page = 1
                this.moreList_show = true
                this.listGET()

            },
            // 点赞 取消点赞
            do_likes(item, index) {
                if (item.is_admire == 0) {
                    this.$axios.post('/api/comment/do_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.moreList[index].is_admire = 1
                                this.moreList[index].likes++
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                } else {
                    this.$axios.post('/api/comment/cancel_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.moreList[index].is_admire = 0
                                this.moreList[index].likes--
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                }

            },
            bookdetailsClick(item) {
                this.$router.push({
                    path: '/bookdetails?id=' + item.id
                })
            },
            evaluationClick() {
                this.$router.push({
                    path: '/evaluation?id=' + this.id
                })
            },
            readingClick() {
                this.$router.push({
                    path: '/reading?id=' + this.id
                })
            },
        }
    }
</script>

<style scoped>
    .text_active {
        color: #4D77FD !important;
    }

    .reading-title {
        color: #4D77FD;
        font-size: 30px;
    }

    .Plus_icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .reading-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 40px;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.1);
    }

    .reading-fixed {
        height: 100px;
    }

    .support-number {
        color: #BFC2CC;
        font-size: 20px;
        display: flex;
        align-items: center;
    }

    .support-time {
        color: #BFC2CC;
        font-size: 20px;
    }

    .support {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    .icon-img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .sentiment-text {
        font-size: 26px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /*决定哪一行截取*/
        -webkit-box-orient: vertical;
        margin-top: 28px;
    }

    .review-book-subtitle {
        color: #BFC2CC;
        font-size: 24px;
    }

    .review-book-title {
        color: #98664A;
        font-size: 24px;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .review-book-top-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .review-book-top {
        display: flex;
        border-bottom: 1px solid #F2F5F8;
        padding: 0 40px 20px;
        margin-top: 40px;
    }

    .book_img1 {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
    }

    .review-title-button {
        color: #4D77FD;
        border: 1px solid #4D77FD;
        border-radius: 23px;
        width: 120px;
        height: 45px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .review-rigth-text {
        color: #BFC2CC;
        font-size: 20px;
        text-align: center;
        margin-top: 10px;
    }

    .review-rigth {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .review-title {
        color: #0A1C33;
        font-size: 36px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 342px;
    }

    .review-subtitle {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
        color: #0A1C33;
    }

    .review {
        display: flex;
        background: #F6FAFF;
        border-radius: 10px;
        padding: 20px;
        margin: 40px;
    }

    .book_img {
        width: 100px;
        height: 139px;
        border-radius: 10px;
        margin-right: 20px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .Stars_icon {
        width: 21px;
        height: 21px;
        margin-left: 10px;
    }
</style>